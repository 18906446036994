/* eslint-disable prefer-destructuring */
import {
  Sidebar,
  Loader,
} from '@/components';
import { mapActions, mapGetters } from 'vuex';
import { yearArray } from '@/utils';

var fileDownload = require('js-file-download');

export default {
  metaInfo: {
    title: 'Exports - IKwatersport',
  },
  name: 'ExportSearch',
  components: {
    [Sidebar.name]: Sidebar,
    [Loader.name]: Loader,
  },
  props: {},
  data: () => ({
    title: 'Exports',
    created: false,
    selectedYear: { label: new Date().getFullYear().toString() },
    yearArray: yearArray.map((year) => ({ label: year })),
  }),
  computed: {
    ...mapGetters('export', {
      buttonIsLoading: 'getButtonLoadingState',
    }),
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('export', {
      generateExport: 'FETCH_EXPORT_EVENT_USERS',
    }),
    updateYear(year) {
      if (year) {
        this.selectedYear = year;
      }
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else {
          // eslint-disable-next-line radix
          const year = parseInt(this.selectedYear.label);
          this.generateExport(year).then((response) => {
            // Access the headers from the response
            const contentDisposition = response.headers['content-disposition'].split(';');
            let filename = '';

            contentDisposition.forEach((param) => {
              const trimmedParam = param.trim();

              if (trimmedParam.startsWith('filename=')) {
                /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
                filename = trimmedParam.split('=')[1]; // Example to get filename from Content-Disposition header
              }
            });
            fileDownload(response.data, filename);
          }).catch(() => {
            this.$toasted.show('Er ging iets mis bij het aanmaken van het rapport.');
          });
        }
      });
    },
  },
  created() {
  },
};
